import { useState, MouseEvent } from 'react';
import classNames from 'classnames';

import { enumToLabel } from '@neo1/core/utils/strings';
import Button, { ButtonTheme } from 'components/elements/Button';
import Link from 'components/elements/Link';
import isEnterOrSpace from 'utils/keyboardEventHelper';
import {
  TableColumns,
  TableRowActions,
  OnRowClick,
  TableProps,
} from '../types';
import formatValue from '../formatValue';
import SelectInput from './SelectInput';
import styles from './CardItem.module.css';
import { useSelection } from '../selectionContext';

type Props = {
  id: string;
  columns: TableColumns;
  row: Object;
  onClick?: OnRowClick;
  rowActions?: TableRowActions;
  rows: Object[];
  renderDetail: TableProps['rowDetail']['render'];
  isRowSelectable: (row: Object) => boolean;
  hideSelectCheckbox: TableProps['hideSelectCheckbox'];
};

const defaultProps: Partial<Props> = {
  onClick: undefined,
  rowActions: [],
};

const MAX_FIELDS = 3;

const CardItem = ({
  id,
  columns,
  row,
  onClick,
  rowActions,
  rows,
  renderDetail,
  isRowSelectable,
  hideSelectCheckbox,
}: Props) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isSelected, setSelected] = useState(false);
  const selectionContext = useSelection();

  if (!columns.length) return null;

  const toggleExpanded = (e: MouseEvent) => {
    e.stopPropagation();
    setExpanded(!isExpanded);
  };

  const handleSelection = (selected: boolean) => {
    setSelected(selected);
  };

  const handleClick = (item: Object) => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <li
      role="row"
      className={classNames(styles.item, { [styles.isSelected]: isSelected })}
      onClick={() => handleClick(row)}
      onKeyDown={(ev) => {
        if (isEnterOrSpace(ev)) {
          handleClick(row);
        }
      }}
      tabIndex={0}
    >
      {columns.map((column, columnIndex) => {
        const label = column.title ?? enumToLabel(column.prop);
        const value = formatValue(row, column, columnIndex, rows);
        if (!value) return null;

        if (columnIndex === 0) {
          return (
            <div
              key={column.prop}
              className={classNames(styles.field, styles.isHeader)}
            >
              <div className={styles.value}>{value}</div>
              {selectionContext && !hideSelectCheckbox ? (
                <SelectInput
                  id={id}
                  className={styles.selectInput}
                  onChange={handleSelection}
                  disabled={isRowSelectable && !isRowSelectable(row)}
                />
              ) : null}
            </div>
          );
        }

        return (
          <div
            key={column.prop}
            className={classNames(styles.field, {
              [styles.isHidden]: !isExpanded && columnIndex >= MAX_FIELDS,
            })}
          >
            <p className={styles.label}>{label}</p>
            <div className={styles.value}>{value}</div>
          </div>
        );
      })}
      {isExpanded && renderDetail && (
        <div className={styles.detailField}>{renderDetail(row)}</div>
      )}
      {columns.length > MAX_FIELDS || renderDetail ? (
        <div className={styles.field}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={toggleExpanded} to="#" standAlone>
            {isExpanded ? 'View less' : 'View more'}
          </Link>
        </div>
      ) : null}

      {rowActions.length > 0 ? (
        <div className={styles.field}>
          {rowActions.map((action) => (
            <Button
              label={action.title}
              iconName={action.icon}
              key={action.name}
              wrapperClassName={styles.actionButton}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick();
              }}
              kind={action.kind}
              theme={ButtonTheme.Outlined}
              disabled={action.isDisabled}
              loading={action.isLoading}
            />
          ))}
        </div>
      ) : null}
    </li>
  );
};

CardItem.defaultProps = defaultProps;

export default CardItem;
